
























































import Vue from 'vue'
export default Vue.extend({
  data() {
    return {
      steps: [
        'Informations générales',
        'Montant MTN',
        'Montant Moov',
        'Récapitulatif'
      ]
    }
  },

  computed: {
    title() {
      const title = this.$route.meta?.stepTitle
      return typeof title === 'function'
        ? title({ params: this.$route.params })
        : title
    },

    activeStep(): number {
      let step = this.$route.meta?.step

      if (
        this.$route.name === 'creditlines.create.deadlines' &&
        this.$route.params.otm === 'moov'
      ) {
        step++
      }

      return step
    }
  }
})
